<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card>
                    <v-card-title class="title">
                        Photos {{album_name.length ? (' For '+ album_name):''}}
                        <v-spacer></v-spacer>
                        <add-button @action="form.dialog = true" permission="image-create">
                            New Photo
                        </add-button>
                        <!--<v-btn outlined @click="form.dialog = true" ma-0 small>New Photo</v-btn>-->
                    </v-card-title>


                    <v-alert v-if="!form.items.data.length && !form.loading"
                             :value="true"
                             type="error">
                        Oops looks like you don't have any images attached to this album({{album_name}}). You can click
                        <a href="" @click.prevent="form.dialog=true">here</a> to add photos.
                    </v-alert>


                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :loading="form.loading"
                            :items="form.items.data"
                            :search="search"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.title }}</td>
                                <td class="text-xs-left">
                                    <div style="border:1px dotted #ccc;display: inline-block;margin: 5px 0;">
                                        <img height="50" :src="item.image" alt="">
                                    </div>
                                </td>
                                <td class="text-right">
                                    <edit-button permission="grade-update" @agree="form.edit(item)"/>
                                    <delete-button permission="grade-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>




                    <!-- <v-layout style="padding: 0 15px;"  wrap>
                        <v-flex
                                v-for="(item,key) in form.items.data"
                                :key="key"
                                xs3
                                d-flex>
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat tile class="d-flex">
                                    <div style="position:absolute;z-index: 99;right: 10px; bottom:10px">
                                        <delete-button permission="image-delete" @agree="form.delete(item.id)" :outlined="false"/>
                                        <edit-button permission="image-update" @agree="form.edit(item)" :outlined="false"/>
                                    </div>
                                    <v-img
                                            :src="item.image"
                                            :lazy-src="item.image"
                                            aspect-ratio="1"
                                            class="grey lighten-2">
                                        <v-expand-transition>
                                            <div v-if="hover"
                                                 class="d-flex transition-fast-in-fast-out blue darken-2 v-card--reveal display-3 white--text"
                                                 style="height: 100%;">
                                                <div class="album_style_mis">
                                                    <h3>{{item.title.toUpperCase()}}</h3>
                                                    <p>{{item.description}}</p>
                                                </div>
                                            </div>
                                        </v-expand-transition>

                                        <template v-slot:placeholder>
                                            <v-layout
                                                    fill-height
                                                    align-center
                                                    justify-center
                                                    ma-0>
                                                <v-progress-circular indeterminate
                                                                     color="grey lighten-5"></v-progress-circular>
                                            </v-layout>
                                        </template>

                                    </v-img>
                                </v-card>
                            </v-hover>
                        </v-flex>
                    </v-layout> -->


                    <!-- <v-card-actions>
                        <v-spacer/>
                        <v-pagination v-if="form.items.data.length > 0 && form.items.meta.total>form.items.perPage" v-model="pagination.page"
                                      :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer/>
                    </v-card-actions> -->
                </v-card>
            </v-flex>

            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update Photo</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                lazy-validation>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-text-field label="Photo Title*" autocomplete="off" required class="pa-0"
                                                      v-model="form.title" name="name" outlined dense
                                                      :error-messages="form.errors.get('title')"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-textarea
                                                :error-messages="form.errors.get('description')"
                                                v-model="form.description"
                                                name="name"
                                                label="Description"
                                                outlined dense
                                                hint="Short and sweet notice is always eye catching."></v-textarea>
                                    </v-flex>
                                    <v-flex vx12>
                                        <v-text-field label="Select Photo"
                                                      v-model="imagePicker.name"
                                                      prepend-inner-icon="add_a_photo"
                                                      @click="onPickFile"
                                                      :error-messages="form.errors.get('image')"
                                                      outlined dense
                                                      required></v-text-field>
                                        <input
                                                type="file"
                                                style="display: none"
                                                ref="image"
                                                accept="image/*"
                                                @change="onFilePicked"
                                        >
                                    </v-flex>
                                    <v-flex xs12 v-if="imagePicker.url">
                                        <v-card>
                                            <v-card-text>
                                                <img :src="imagePicker.url" height="150"/>
                                                <v-btn
                                                        fab
                                                        small
                                                        bottom
                                                        right
                                                        absolute
                                                        @click.native.stop="resetImagePicker"
                                                        style="z-index: 1"
                                                        color="red white--text"
                                                >
                                                    <v-icon dark>close</v-icon>
                                                </v-btn>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="store">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import ImageCompressor from 'image-compressor.js'

    export default {
        data: () => ({
            form: new Form({
                title: '',
                description: '',
                image: '',
                albums: ''
            }, '/api/photo'),
            search: null,
            pagination: {
                rowsPerPage: 10
            },
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            album_name: ''
        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination.page': function () {
                this.get();
            }
        },
        mounted() {
            this.get();
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&albumId=' + this.$route.params.albumId;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total;
                    this.album_name = data.album_name;
                })
            },

            store() {
                this.form.image = this.imagePicker.image;
                this.form.albums = this.$route.params.albumId;
                this.form.store().then(() => {
                    this.resetImagePicker();
                    this.get();
                });
            },
            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    this.$store.dispatch('notifyError', "Please upload a valid image file!");
                    return
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener('load', () => {
                    this.imagePicker.url = fileReader.result;
                    this.imagePicker.name = fileName

                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(files[0], {quality: .5})
                        .then((result) => {
                            this.imagePicker.image = result
                        })
                        .catch((err) => {
                            // Handle the error
                        })
                })
            },
            resetImagePicker() {
                this.imagePicker = {
                    url: undefined,
                    image: undefined,
                    name: undefined
                };
                this.$refs.image.value = "";
            },


            /**
             * Image Upload Handler
             */
            onPickFile() {
                this.$refs.image.click()
            },

        }
    }
</script>
<style lang="scss" scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }

    .album_style_mis {
        padding: 0 20px;
        width: 50% !important;

        h3 {
            font-size: 17px !important;
            border-bottom: 1px solid white;
        }

        p {
            font-size: 15px;
        }
    }

    .container.grid-list-md .layout .flex {
        margin-bottom: 40px;
    }

    .v-btn--bottom.v-btn--absolute.v-btn--small.edit {
        right: 60px;
    }
</style>